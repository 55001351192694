export default {
  'slot1': "8664724314",
  'slot2': "8914838464",
  'slot3': "4342335923",
  'firebaseConfig': {
  apiKey: "AIzaSyD6xPqu0Zs1JCg8QS2DCq9msH0VRNhj7dI",
  authDomain: "news8-network.firebaseapp.com",
  projectId: "news8-network",
  storageBucket: "news8-network.appspot.com",
  messagingSenderId: "659808392711",
  appId: "1:659808392711:web:af5a293b192badc86f3ca2",
  measurementId: "G-7Q5Z0FYGFZ"
  }
}